import { Pipe, PipeTransform } from '@angular/core';

import { EventDataSource, EventDataSourceType } from 'src/app/features/engagements/pages/engagements-dashboard/engagements-data-sources/models';

@Pipe({
	name: 'eventDataSourceFilter',
	standalone: true,
})
export class EventDataSourceFilterPipe implements PipeTransform {
	transform(eventDataSources: EventDataSource[], dataSourceType: EventDataSourceType): EventDataSource[] {
		if (eventDataSources) {
			return eventDataSources.filter(d => !dataSourceType || d.eventDataSourceTypeId === dataSourceType.id);
		}

		return eventDataSources;
	}
}
